import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import EryButton from 'ery-components/src/lib/EryButton';
import { Section } from 'ery-components/src/lib/EryLandingLayout';

const styles = {
  planButton: {
    maxWidth: '255px',
  },
  planDesc: {
    marginBottom: '.25em',
  },
  planItem: {
    backgroundColor: '#ffffff',
    border: 'solid 3px #86c56b',
    borderRadius: '17px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.5)',
    margin: '1rem',
    maxWidth: '434px',
    padding: '2rem 1.5rem',
  },
};

const plans = [
  {
    name: 'basic',
    numberOfAccounts: 1,
    features: [
      'May buy File Access',
      'Max 250MB of data upload',
      'Run one Experiment at a time',
      'Subject payment via Ery Wallet (at 8% fee)',
      'Access to community Support',
    ],
    price: 'Free',
  },
  {
    name: 'premium',
    numberOfAccounts: 1,
    features: [
      'May buy File Access',
      'Max 250MB of data upload',
      'May run X experiments at a time',
      'May run with robots',
      'Subject payment via Ery wallet (at 4% fee)',
      'Access to support team within 72h',
    ],
    price: 'Request price',
  },
  {
    name: 'professional',
    numberOfAccounts: 1,
    features: [
      'May buy and sell file access',
      'Max 100Gb of data upload',
      'Max 5 Laboratories',
      'May run 5X experiments at a time',
      'May run with robots',
      'Subject payment via Ery wallet (at 4% fee)',
      'Direct mobile top-up',
      'Access to support team within 24h',
    ],
    price: 'Request price',
  },
  {
    name: 'research center',
    numberOfAccounts: '5+',
    features: [
      'Max 1TB of data upload',
      'May run 7X experiments at a time',
      'Max 35 Laboratories',
      'May run with robots',
      'Subject payment via Ery wallet (at 3% fee)',
      'Direct mobile top-up',
      'Access to support team within 72h',
    ],
    price: 'Request price',
  },
];

const PlanItem = withStyles(styles)(({ classes, name, numberOfAccounts, features, price }) => (
  <Box className={classes.planItem}>
    <Grid container direction='column' alignItems='center'>
      <Grid item>
        <Typography variant='h3'> {name} </Typography>
      </Grid>
      <Grid item>
        <Typography variant='h4'>
          {numberOfAccounts} account{numberOfAccounts != '1' && 's'}
        </Typography>
      </Grid>
      <ul>
        {features.map((feature, i) => (
          <li key={i}>{feature}</li>
        ))}
      </ul>
      <Grid item>
        <EryButton className={classes.planButton}> {price} </EryButton>
      </Grid>
    </Grid>
  </Box>
));

const Plans = ({ classes }) => (
  <Section title='Plans'>
    <Grid container justify='center'>
      {plans.map((plan, i) => (
        <Grid item key={i}>
          <PlanItem {...plan} />
        </Grid>
      ))}
    </Grid>
    <Typography variant='body2'> * Extra accounts for research centers(with professional specs) available </Typography>
  </Section>
);

Plans.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Plans);

import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Layout, { Banner } from 'ery-components/src/lib/EryLandingLayout';

import About from '../components/About';
import Features from '../components/Features';
import Varieties from '../components/Varieties';
import Plans from '../components/Plans';

import BannerImg from '../images/banner.jpg';
import auth0 from '../utils/auth0';

import withRoot from '../withRoot';

const IndexPage = () => (
  <Layout auth0={auth0}>
    <Box mb={3}>
      <Banner image={BannerImg}>create your experiment now!</Banner>
    </Box>
    <Grid container>
      <Grid item>
        <About />
      </Grid>
      <Grid item>
        <Features />
      </Grid>
      <Grid item>
        <Varieties />
      </Grid>
      <Grid item>
        <Plans />
      </Grid>
    </Grid>
  </Layout>
);

export default withRoot(IndexPage);

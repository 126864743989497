import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { Paragraph, Section } from 'ery-components/src/lib/EryLandingLayout';

import FeautureImage1 from '../images/tool1.png';
import FeautureImage2 from '../images/tool2.png';
import FeautureImage3 from '../images/tool3.png';
import FeautureImage4 from '../images/tool4.png';
import FeautureImage5 from '../images/tool5.png';
import FeautureImage6 from '../images/tool6.png';

const styles = theme => ({
  root: {},
  feature: {
    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
    textAlign: 'center',
  },
});

const features = [
  {
    image: FeautureImage1,
    title: 'Create once, and maintain one implementation',
    text: 'The same experiments or survey created for run on the Web can be automatically converted to Mobile and SMS',
  },
  {
    image: FeautureImage2,
    title: 'Recruit subjects your way',
    text:
      'You may recruit subjects by offering it up online to our pool of workers. You may send a link to people you want to partake. You may have people opt-in via SMS and more!',
  },
  {
    image: FeautureImage3,
    title: 'Conduct Longitudinal Studies',
    text:
      'Want to find out if someones preferences, choices or culture has changed between days, weeks, months or years. Have the same subjects be reinvited to your next experiment.',
  },
  {
    image: FeautureImage4,
    title: 'Complexity when you need it, otherwise simple',
    text:
      'No need to know any programming, or even what programming is. Create surveys using point-and-click. But if your task is complex, all the potentials of the underlying system can be unlocked. So anything do via a web browser - can also be done via Behavery.',
  },
  {
    image: FeautureImage5,
    title: 'Subject interaction on Networks',
    text:
      'We know from all social network theory that the network topology is important. Behavery allows you to include heterogeneous networks seamlessly into your experiments.',
  },
  {
    image: FeautureImage6,
    title: 'Reach subjects outside of the WEIRD world',
    text:
      'We support payment to subjects via bank transfers in X currencies and Y countries. We may pay subjects in India by topping up their cell phone, and many more.',
  },
];

const Feautures = ({ classes }) => (
  <Section title='Features'>
    <Typography variant='h5'>Complexity when you need it, otherwise simple</Typography>
    <Typography variant='h6'>So anything do via a web browser - can also be done via Behavery</Typography>
    <Box m='1em'>
      <Grid container alignItems='center' justify='center'>
        {features.map((feature, i) => (
          <Grid item key={i}>
            <Box className={classes.feature}>
              <img src={feature.image} alt='Feauture' />
              <h4>{feature.title}</h4>
              <p>{feature.text}</p>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Section>
);

Feautures.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Feautures);

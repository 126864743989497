import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import 'plyr-react/dist/plyr.css';

import { Paragraph, Section } from 'ery-components/src/lib/EryLandingLayout';

const useStyles = makeStyles(theme => ({
  plyr: {
    '--plyr-color-main': theme.palette.primary.main,
  },
}));

const About = () => {
  const [Plyr, setPlyr] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setPlyr(require('plyr-react').Plyr);
  }, []);

  return (
    <Section title='About'>
      <Grid container justify='space-between'>
        <Grid item md={7}>
          <Paragraph>
            Behavery is a cloud-based platform for experiments and surveys in behavioral sciences and market research as well
            as for outsourcing microtasks. It can be used for psychological studies and decision-making experiments both in
            controlled lab-settings and for online studies. Real-time multi-participant experiments allows you to explore
            cooperative behavior, joint action, socio-economic games, and much more, as the experiments allow for interaction
            between concurrent participants.
          </Paragraph>
          <Paragraph>
            Behavery is an integrated system. Within the same platform, researchers implement and execute experiments and
            subjects sign up and participate in the experiments. Recorded participant data is reasily available for download at
            any time for the researchers.
          </Paragraph>
          <Paragraph>
            Behavery has an intuitive interface for implementing experiments, and is designed to be accessible to users without
            programming background. Use point-and-click to create surveys and to include images, video, and audio to show to
            the participants.
          </Paragraph>
        </Grid>
        <Grid item md={5}>
          <Box className={classes.plyr} marginLeft='1em' minWidth={400}>
            {Plyr && (
              <Plyr
                source={{
                  type: 'video',
                  sources: [
                    {
                      provider: 'youtube',
                      src: '471dnOQpiWU',
                    },
                  ],
                  youtube: { color: 'white', iv_load_policy: 0, modestbranding: 1, rel: 0 },
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};

About.propTypes = {
  siteTitle: PropTypes.string,
};

About.defaultProps = {
  siteTitle: '',
};

export default About;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  variety: {
    position: 'relative',
    margin: '1rem  1rem',
    padding: '1rem 1rem 1rem 1rem',
    textAlign: 'center',
    borderRadius: '7px',
    border: '3px solid #86c56b',
    fontSize: '18px',
    fontWeight: '500',
    color: '#0c4229',
    cursor: 'pointer',
    height: '65px',
  },
  varietyOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    right: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#a9d298cc',
  },
});

const VarietyItem = props => {
  const { classes, variety } = props;
  const [state, setState] = useState({ isOverlayVisible: false });

  const showOverlay = () => {
    setState({
      isOverlayVisible: true,
    });
  };
  const hideOverlay = () => {
    setState({
      isOverlayVisible: false,
    });
  };

  return (
    <Box className={classes.variety} onMouseEnter={showOverlay} onMouseLeave={hideOverlay}>
      {variety}
      {state.isOverlayVisible && <Box className={classes.varietyOverlay}>Edit Demo</Box>}
    </Box>
  );
};

VarietyItem.propTypes = {
  classes: PropTypes.object.isRequired,
  variety: PropTypes.string,
};

export default withStyles(styles)(VarietyItem);

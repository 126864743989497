import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Section, Paragraph } from 'ery-components/src/lib/EryLandingLayout';

import VarietyItem from './VarietyItem';

const varieties = [
  'Auction Experiments',
  'Repeated Public Goods',
  'Ultimatum Game',
  'Reaction Time',
  'Implicit Association Task',
  'Categorization Tasks',
  'Eyetracking Experiments',
  'Video Reaction',
  'Incentivized surveying',
  'Decision Making',
  'Decision Aid',
  'Short-term Memory Test',
];

const Varieties = () => (
  <Section title='A versatile platform'>
    <Box mb='2rem'>
      <Grid container justify='center'>
        {varieties.map((variety, i) => (
          <Grid item key={i}>
            <VarietyItem variety={variety} />
          </Grid>
        ))}
      </Grid>
    </Box>
  </Section>
);

Varieties.propTypes = {
  siteTitle: PropTypes.string,
};

Varieties.defaultProps = {
  siteTitle: '',
};

export default Varieties;
